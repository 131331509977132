export const tableColumnsLatestDeals = [
  {
    Header: "AdSpace",
    accessor: "adspace_name",
  },
  {
    Header: "Campaign",
    accessor: "campaign_name",
  },
  {
    Header: "Final price",
    accessor: "price",
  },
  {
    Header: "Started",
    accessor: "started_at",
  },
];
