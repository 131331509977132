export const tableColumnsTopAdSpaces = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Deals",
    accessor: "count_deals",
  },
  {
    Header: "Revenue",
    accessor: "total_revenue",
  },
];
